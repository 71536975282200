.login_page_holder {
	width: 100%;
	height: calc(100vh - 120px);
	display: flex;
	align-items: center;
	justify-content: center;

	.form_holder {
		width: 600px;
		display: flex;
		flex-direction: column;
		align-items: center;

		h1 {
			margin-bottom: 10px;
		}

		.input_field {
			margin-bottom: 10px;
		}

		.error_alert {
			margin-top: 10px;
			margin-bottom: 20px;
		}
	}
}